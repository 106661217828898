<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
        <div class="page-desc">
           耗材处置说明
         </div>   
		<TableVue ref="c_table"></TableVue>
	</div>   
</template>
<script>
import TableVue from '../Common/ConsumableTable.vue'
export default {
    components:{
		TableVue,
    },
	 inject:['reload'],
   data() {
      return {
      }
   },
   created(){
		this.$nextTick(()=>{
			this.$refs.c_table.rUrl = {
				index:'/CseDisposal/index',
				add:'/CseDisposal/add', 
				edit:'/CseDisposal/edit',
				del:'/CseDisposal/del',
				export:'/CseDisposal/export'
			} 
			this.$refs.c_table.cardName =  '耗材处置单'
			this.$refs.c_table.storageType = 5
			this.$refs.c_table.getData();
		})
   },
   mounted(){
		
   },
   methods:{
		
   }

}
</script>

</style>